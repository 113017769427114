import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDwiS-_wVkkzg2qgjG2GUn_FxKRu9FQHt4",
  authDomain: "jimmy-shinchi.firebaseapp.com",
  databaseURL: "https://jimmy-shinchi.firebaseio.com",
  projectId: "jimmy-shinchi",
  storageBucket: "jimmy-shinchi.appspot.com",
  messagingSenderId: "754787054617",
  appId: "1:754787054617:web:4788a419d629fb05"
};

firebase.initializeApp(config);
firebase.firestore()

export default firebase;