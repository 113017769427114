export const createTokenTransfer = (tokenTransfer) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('TokenTransfer').add({
      ...tokenTransfer,
      txHash: "",
      createdAt: new Date(),
    }).then(() => {
      dispatch({ type: 'CREATE_TOKEN_TRANSFER', tokenTransfer });
    }).catch((err) => {
      dispatch({ type: 'CREATE_TOKEN_TRANSFER_ERROR', err });
    })
  }
}