import React from 'react';
import { useWeb3Context } from 'web3-react';
import WalletPage from './WalletPage';

export default function Wallet() {
  const {library} = useWeb3Context()

  return (
    <WalletPage provider={library} account={null} />
  )
}

