import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ethers } from 'ethers';
import PropTypes from 'prop-types';
import { createTokenTransfer } from '../../store/actions/tokenTransferActions';
import { web3Wallet } from '../../utils/web3-wallet';
import {
  TOKEN_CONTRACT_ADDRESS,
  TOKEN_CONTRACT_ABI,
  DELEGATE_CONTRACT_ADDRESS,
  DELEGATE_CONTRACT_ABI,
} from '../../config/contractConfig';
import WalletTx from './WalletTx';

class WalletPage extends Component {
  state = {
    receiver: '0xD3C2C23CC16d94d595DD5B27D9500F3842824f57',
    amount: '',
    nonce: 0,
    balance: 0,
    result: null,
    name: '',
    symbol: '',
  }

  constructor(props) {
    super(props);
    const { provider } = this.props;
    const { signer, account, isDEXONWallet } = web3Wallet(this.props);
    this.signer = signer;
    this.account = account;
    this.isDEXONWallet = isDEXONWallet;
    this.tokenContract = new ethers.Contract(TOKEN_CONTRACT_ADDRESS, TOKEN_CONTRACT_ABI, provider);
    this.delegateContract = new ethers.Contract(DELEGATE_CONTRACT_ADDRESS, DELEGATE_CONTRACT_ABI, provider);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let amount = ethers.utils.bigNumberify(this.state.amount).mul(ethers.constants.WeiPerEther);
    let data = ethers.utils.concat([
      this.account,
      this.state.receiver,
      ethers.utils.padZeros(ethers.utils.arrayify(amount), 32),
      ethers.utils.padZeros(ethers.utils.arrayify(
        ethers.utils.bigNumberify(this.state.nonce)
      ), 32),
    ])
    let hash = ethers.utils.arrayify(ethers.utils.keccak256(data));
    this.setState({
      result: 
        `Amount: ${amount.toHexString()}\n` + 
        `Msg: ${ethers.utils.keccak256(data)}\n` +
        `Hash: ${ethers.utils.hashMessage(hash)}`
    })

    if(this.isDEXONWallet) {
      hash = ethers.utils.arrayify(ethers.utils.hashMessage(hash));
    }
    this.signer.signMessage(hash).then(sig => {
      this.setState({
        result: this.state.result + `\nSignature: ${sig}`
      })
      var tokenTransfer = {
        sender: this.account,
        receiver: this.state.receiver,
        amount: amount.toHexString(),
        nonce: ethers.utils.bigNumberify(this.state.nonce).toHexString(),
        signature: sig,
      };
      this.props.createTokenTransfer(tokenTransfer)
      this.setState({
        amount: '',
        nonce: Number(this.state.nonce)+1,
      })
    });
  }
  update() {
    this.tokenContract.allowance(this.account, DELEGATE_CONTRACT_ADDRESS).then((data) => {
      this.setState({
        balance: data.div(ethers.constants.WeiPerEther).toNumber(),
      })
    });
    this.delegateContract.delegateNonce(this.account).then((data) => {
      this.setState({
        nonce: data.toString(),
      })
    })
  }
  componentDidMount() {
    this.update()
    let filter = this.tokenContract.filters.Transfer(this.account);
    this.tokenContract.on(filter, () => {
      this.update()
    });
    this.tokenContract.name().then((data) => {
      this.setState({
        name: data
      })
    });
    this.tokenContract.symbol().then((data) => {
      this.setState({
        symbol: data
      })
    });
  }

  render() {
    return (
      <div className="container WalletPage">
        <div className="row">
          <div className="col m7">
            <WalletTx provider={this.provider} account={this.account} />
          </div>
          <div className="col m5">
            <form onSubmit={this.handleSubmit} className="white">
              <h5 className="grey-text text-darken-3">Transfer Token</h5>
              <p>{this.state.name} Balance: {this.state.balance} {this.state.symbol}</p>
              <div className="input-field">
                <label className="active" htmlFor="receiver">Receiver</label>
                <input type="text" id="receiver" onChange={this.handleChange} value="0xD3C2C23CC16d94d595DD5B27D9500F3842824f57" />
              </div>
              <div className="input-field">
                <label htmlFor="amount">Amount</label>
                <input type="text" id="amount" onChange={this.handleChange} value={this.state.amount} />
              </div>
              <div className="input-field">
                <label className="active" htmlFor="nonce">Nonce</label>
                <input type="text" id="nonce" onChange={this.handleChange} placeholder={this.state.nonce} />
              </div>
              <div className="input-field">
                <button className="btn pink lighten-1 z-depth-0">Transfer</button>
              </div>
              <div className="red-text center">
                {this.state.result ? <pre>{this.state.result}</pre> : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createTokenTransfer: (tokenTransfer) => dispatch(createTokenTransfer(tokenTransfer)),
  }
}

export default connect(null, mapDispatchToProps)(WalletPage);

WalletPage.propTypes = {
  provider: PropTypes.any,
  account: PropTypes.any,
  createTokenTransfer: PropTypes.func,
};
