export const TOKEN_CONTRACT_ADDRESS = "0x90388000CdF3aA0C573fF1CC0BfBfF51c306Ac7E";
export const TOKEN_CONTRACT_ABI = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function balanceOf(address) external view returns (uint256)",
  "function allowance(address, address) external view returns (uint256)",
  "event Transfer(address indexed, address indexed, uint256)",
];
export const DELEGATE_CONTRACT_ADDRESS = "0x88362f8CF003bBe1e750D0f560c505Af036255f6";
export const DELEGATE_CONTRACT_ABI = [
  "function delegateTransfer(address _contract, address _sender, address _receiver, uint256 _amount, uint256 _nonce, bytes _signature) public returns (bool)",
  "function delegateNonce(address) view returns(uint256)",
]