import { ethers } from 'ethers';


// addr 0x4EEC7Ad602587b40dC4C55a43EeC2F7661cd8279
const privateKey = "0xd297b611fdc4941820b9c9bb1284ca95fdf54973896e9b813ade205d25e6c76a";

function web3Wallet({provider, account}) {
  let obj = {
    signer: null,
    account: account,
    isDEXONWallet: false,
  };
  if(obj.account === null){
    obj.signer = new ethers.Wallet(privateKey, provider);
    obj.account = obj.signer.address;
    obj.isDEXONWallet = false;
  } else {
    obj.signer = provider.getSigner();
    obj.account = account;
    obj.isDEXONWallet = true;
  }
  return obj
}

export { web3Wallet };