import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Button } from 'react-materialize';
import { ethers } from 'ethers';


function WalletTx({transfers, account}) {
  return (
    <div className="WalletTx">
      <h5>Transfer History</h5>
      <h6>
        <Button node="a"
          className="btn blus lighten-1 z-depth-0"
          href={`https://dexonscan.app/address/${account}`}
          target="_blank" rel="noreferrer noopener">
                View Account DEXONScan
        </Button>
      </h6>
      <table className="highlight">
        <thead>
          <tr>
            <th>Status</th>
            <th>Receiver</th>
            <th>Amount</th>
            <th>Nonce</th>
          </tr>
        </thead>
        <tbody>
          {transfers && transfers.map(transfer => {
            let status;
            if('error' in transfer) {
              status = (
                <Button className="pink lighten-1 z-depth-0" tooltip={transfer.error}>
                    Error
                </Button>
              )
            } else {
              status = transfer.txHash ==="" ? (
                <Button className="blue lighten-1 z-depth-0" tooltip="Contact support if the transaction is pending for too long.">
                  Pending
                </Button>
              ) : (
                <Button node="a"
                  className="green lighten-1 z-depth-0"
                  href={`https://dexonscan.app/transaction/${transfer.txHash}`} 
                  target="_blank" rel="noreferrer noopener"
                  tooltip="View on DEXONScan">
                  Success
                </Button>
              )
            }
            return (
              <tr key={transfer.id}>
                <td>{status}</td>
                <td>{transfer.receiver}</td>
                <td>{ethers.utils.bigNumberify(transfer.amount).div(ethers.constants.WeiPerEther).toString()}</td>
                <td>{ethers.utils.bigNumberify(transfer.nonce).toString()}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

WalletTx.propTypes = {
  account: PropTypes.string,
  transfers: PropTypes.array,
}


const mapStateToProps = (state) => {
  return {
    transfers: state.firestore.ordered.TokenTransfer ? state.firestore.ordered.TokenTransfer : [],
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'TokenTransfer',
        where : ['sender', '==', props.account ],
        orderBy: ['createdAt', 'desc'] },
    ]
  }),
)(WalletTx);