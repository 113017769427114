import React, { useEffect } from 'react';
import { useWeb3Context } from 'web3-react';
import Web3Provider from 'web3-react';
import PropTypes from 'prop-types';
import connectors from './web3-connector';

function Web3LoaderImp({children}) {
  const context = useWeb3Context()

  useEffect(() => {
    context.setFirstValidConnector(['DEXONWallet', 'DEXON'])
  }, [context])

  let success = false;
  let status;
  if (!context.active && !context.error) {
    status = 'loading...';
  } else if (context.error) {
    status = 'error ' + context.error;
  } else {
    status = 'success';
    success = true;
  }
  return success ?
    children :
    (
      <div className="container">
        <h2>{status}</h2>
      </div>
    );

}

export default function Web3Loader({children}) {
  return (
    <Web3Provider
      connectors={connectors}
      libraryName={'ethers.js'}
    >
      <Web3LoaderImp>
        {children}
      </Web3LoaderImp>
    </Web3Provider>
  )
}

Web3Loader.propTypes = {
  children: PropTypes.any
}

Web3LoaderImp.propTypes = {
  children: PropTypes.any
}