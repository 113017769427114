import React from 'react';
import PropTypes from 'prop-types';
import { ethers } from 'ethers';
import { Button } from 'react-materialize';
import { DELEGATE_CONTRACT_ADDRESS, DELEGATE_CONTRACT_ABI } from '../../config/contractConfig.js';
import { TOKEN_CONTRACT_ADDRESS } from '../../config/contractConfig.js';


const sendTx = (signer, item) => {
  let contract = new ethers.Contract(DELEGATE_CONTRACT_ADDRESS, DELEGATE_CONTRACT_ABI, signer);
  contract.delegateTransfer(TOKEN_CONTRACT_ADDRESS, item.sender, item.receiver, item.amount, item.nonce, item.signature, {
    gasPrice: signer.provider.getGasPrice(),
  })
    .then(
      e=>console.log(e)
    );
}


const List = ({transfers, signer}) => {
  return (
    <div className="section">
      <h5>Admin Panel</h5>
      <table className="highlight">
        <thead>
          <tr>
            <th>Action</th>
            <th>Sender</th>
            <th>Receiver</th>
            <th>Amount</th>
            <th>Nonce</th>
          </tr>
        </thead>
        <tbody>
          {transfers && transfers.map(transfer => {
            let action;
            if('error' in transfer) {
              action = (
                <span>
                  <Button className="pink lighten-1 z-depth-0" onClick={()=>{sendTx(signer, transfer)}} tooltip={`Previous error: ${transfer.error}`}>
                    Retry
                  </Button>
                </span>
              )
            } else {
              action = transfer.txHash ==="" ?
                (
                  <Button className="blue lighten-1 z-depth-0" onClick={()=>{sendTx(signer, transfer)}}>
                    Approve
                  </Button>
                ) : (
                  <a 
                    className="btn green lighten-1 z-depth-0"
                    href={`https://dexonscan.app/transaction/${transfer.txHash}`} 
                    target="_blank" rel="noreferrer noopener">
                  View
                  </a>
                )
            }
            return (
              <tr key={transfer.id}>
                <td>{action}
                  
                </td>
                <td>{transfer.sender}</td>
                <td>{transfer.receiver}</td>
                <td>{ethers.utils.bigNumberify(transfer.amount).div(ethers.constants.WeiPerEther).toString()}</td>
                <td>{ethers.utils.bigNumberify(transfer.nonce).toString()}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

List.propTypes = {
  transfers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ),
  signer: PropTypes.any
}

export default List;