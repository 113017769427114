import React from 'react';
import { useWeb3Context } from 'web3-react';
import Dashboard from './Dashboard';

export default function Admin() {
  const {library, account} = useWeb3Context()

  return (
    <Dashboard provider={library} account={account} />
  )
}

