import { Connectors } from 'web3-react';

const { InjectedDEXONConnector, NetworkOnlyConnector } = Connectors

const DEXON = new NetworkOnlyConnector({
  providerURL: 'https://mainnet-rpc.dexon.org'
})

const DEXONWallet = new InjectedDEXONConnector({
  supportedNetworks: [237, 238],
})

const connectors = { DEXON, DEXONWallet }

export default connectors