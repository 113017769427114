import React from 'react';
import { BrowserRouter,Switch, Route } from 'react-router-dom';
import Wallet from './components/wallet/Wallet';
import Admin from './components/admin/Admin';
import Navbar from './components/layout/Navbar';
import Web3Loader from './utils/web3-loader'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Web3Loader>
          <Switch>
            <Route exact path="/" component={Wallet} />
            <Route path="/wallet" component={Wallet} />
            <Route path="/admin" component={Admin} />
          </Switch>
        </Web3Loader>
      </div>
    </BrowserRouter>
  );
}

export default App;
