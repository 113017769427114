import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import List from './List';
import { web3Wallet } from '../../utils/web3-wallet';

class Dashboard extends Component {
  render() {
    const { transfers } = this.props;
    const { signer } = web3Wallet(this.props);
    return (
      <div className="container Admin">
        <List transfers={transfers} signer={signer} />
      </div>
    )
  }
}

Dashboard.propTypes = {
  transfers: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    transfers: state.firestore.ordered.TokenTransfer,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'TokenTransfer', orderBy: ['createdAt', 'desc'] },
  ]),
)(Dashboard);